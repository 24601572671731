import { ApiResponse } from "apisauce";
import { Api } from "./api";
import { getGeneralApiProblem } from "./api-problem";

export class lakeApi {
  private api: Api;

  constructor(api: Api) { this.api = api; }

  async getCard(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(`/river/card`);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) { return { kind: "bad-data" }; }
  }

  async getDevice(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(`/river/device`);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) { return { kind: "bad-data" }; }
  }

  async changeStatus(payload: any): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post("/river/action", payload);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) { return { kind: "bad-data" }; }
  }
}