import { ApiResponse, ApisauceInstance, create } from "apisauce";
import { ApiConfig } from "./api-config";
import { AlarmApi } from "./alarmApi";
import { LoginApi } from "./loginApi";
import { ReportsApi } from "./reportApi";
import { CustomApi } from "./baseApi";
import { CardsApi } from "./cardsApi";
import { MapApi } from "./mapApi";
import { getGeneralApiProblem } from "./api-problem";
import { rootStore } from "models";
import config from "config";

import { Cart_CounterApi } from "./cart_counterApi";
import { CEVTPTApi } from "./cevtprApi";
import { EnergyMonitorApi } from "./energy_monitorApi";
import { energyfailure_tempprobeApi } from "./energyfailure_tempprobeApi";
import { interface_ethernetApi } from "./interface_ethernetApi";
import { LevelContainerApi } from "./level_containerApi";
import { level_channelApi } from "./level_channelApi";
import { lakeApi } from "./level_riverApi";
import { Level_TankApi } from "./level_tankApi";
import { LevelSensor2Api } from "./level_sensorApi";
import { LoopsApi } from "./loopsApi";
import { MceApi } from "./mceApi";
import { RoadsignApi } from "./roadsignApi";
import { TrackerApi } from "./trackerApi";
import { WaterApi } from "./waterApi";

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: config.api.baseUrl,
  timeout: 100000,
};

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce!: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }

  removeToken() {
    this.apisauce.deleteHeader("Authorization");
  }

  setToken(token: string) {
    this.apisauce.setHeader("Authorization", "Bearer " + token);
  }

  async getCard(url: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.apisauce.get(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) { return { kind: "bad-data" }; }
  }

  async getGroup(service: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.apisauce.get(
        `${service}/groups`
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      console.log(data)
      return data;
    } catch (error) { return { kind: "bad-data" }; }
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: { Accept: "application/json" },
    });
  }
}

const baseApi = new Api();
baseApi.setup();
const api = {
  api: baseApi,
  alarms: new AlarmApi(baseApi),
  login: new LoginApi(baseApi),
  reports: new ReportsApi(baseApi),
  cards: new CardsApi(baseApi),
  maps: new MapApi(baseApi),

  alarm_neighbor: new CustomApi(baseApi, "alarm_neighbor"),
  cart_counter: new Cart_CounterApi(baseApi),
  cevtpr: new CEVTPTApi(baseApi),
  energy_efficiency: new CustomApi(baseApi, "energy_efficiency"),
  energymonitor: new EnergyMonitorApi(baseApi),
  energyfailure_tempprobe: new CustomApi(baseApi, "energyfailure_tempprobe"),
  freezer: new CustomApi(baseApi, "freezer"),
  gas: new CustomApi(baseApi, "gas"),
  dw: new CustomApi(baseApi, "gateway"),
  interface_ethernet: new CustomApi(baseApi, "interface_ethernet"),
  level_channel: new CustomApi(baseApi, "level_channel"),
  levelcontainer: new LevelContainerApi(baseApi),
  lake: new lakeApi(baseApi),
  level_tank: new Level_TankApi(baseApi),
  levelsensor2: new LevelSensor2Api(baseApi),
  level_tunnel: new CustomApi(baseApi, "level_tunnel"),
  loops: new LoopsApi(baseApi),
  mce: new MceApi(baseApi),
  nema: new CustomApi(baseApi, "nema"),
  parking: new CustomApi(baseApi, "parking"),
  people_counter: new CustomApi(baseApi, "people_counter"),
  residential: new CustomApi(baseApi, "residentialWaterMeters"),
  roadsign: new RoadsignApi(baseApi),
  sense_4_20mA: new CustomApi(baseApi, "sense_4_20mA"),
  sense_modbus: new CustomApi(baseApi, "sense_modbus"),
  tracker: new TrackerApi(baseApi),
  transformer: new CustomApi(baseApi, "transformer"),
  transit: new CustomApi(baseApi, "loop"),
  water: new WaterApi(baseApi, "water"),
  weather: new CustomApi(baseApi, "weather"),
};

const responseMonitor = (response: any) => {
  if (response.status === 403 || response.status === 401) {
    api.api.removeToken();
    rootStore.usersStore.removeToken();
    return;
  }
};
api.api.apisauce.addMonitor(responseMonitor);

export default api;