import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

const Coordinates = types.model("coordinates").props({ coordinates: types.array(types.number) });

const AlarmsModel = types.model("Alarms").props({
  id: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  connected: types.maybeNull(types.boolean),
  connectedDate: types.maybeNull(types.string),
  location: types.maybeNull(Coordinates),
  group: types.maybeNull(types.string),
  baterry: types.maybeNull(types.number),
  counter_status: types.maybeNull(types.number),
  temperature: types.maybeNull(types.number),
  serial: types.maybeNull(types.string),
  snr: types.maybeNull(types.number),
  rssi: types.maybeNull(types.number),
  energy: types.maybeNull(types.boolean),
  door: types.maybeNull(types.boolean),
  vibration: types.maybeNull(types.boolean),
});

const Location = types.model("Location").props({ lat: types.number, lng: types.number });

export const alarm_neighborModelAlarm = types
  .model("alarm_neighbor")
  .props({
    alarms: types.optional(types.array(AlarmsModel), []),
    locationSelected: types.maybeNull(Location),
    idSelected: types.maybeNull(types.string),
    isLoading: types.boolean,
    idLoading: types.maybeNull(types.string),
    center: Location,
  })
  .views((self) => ({
    filterValues(value: string | null) {
      if (value === null) return self.alarms;
      return self.alarms.filter(
        (alarm) =>
          alarm.id?.toLowerCase().includes(value.toLowerCase())
      );
    },
  }))
  .actions((self) => {
    function setAlarms(alarms: any) { self.alarms = alarms; }
    function setlocationSelected(cordinates: any) { self.locationSelected = cordinates; }
    function setId(id: any) { self.idSelected = self.idSelected === id ? null : id; }
    function setIsLoading(isLoading: boolean, id: string | null) { self.idLoading = id; self.isLoading = isLoading; }
    function setLoading(loading: any) { self.isLoading = loading; }
    function setCenter(coordinates: any) { self.center = coordinates; }
    return { setAlarms, setlocationSelected, setCenter, setIsLoading, setLoading, setId };
  })
  .actions((self) => ({
    getDevices: async () => {
      try {
        self.setLoading(true);
        const alarmsApi = api.alarm_neighbor;
        const response = await alarmsApi.getDevice();
        self.setAlarms(response?.devices);
        self.setCenter(response?.center);
        self.setLoading(false);
        self.setIsLoading(false, null);
      } catch (error) { console.log(error); }
    },
  }));

type alarm_neighborModelType = Instance<typeof alarm_neighborModelAlarm>;
export type alarm_neighbor = alarm_neighborModelType;
type alarm_neighborSnapshotType = SnapshotOut<typeof alarm_neighborModelAlarm>;
export type alarm_neighborSnapshot = alarm_neighborSnapshotType;
export const alarm_neighborDefaultModel = () => types.optional(alarm_neighborModelAlarm, { alarms: [], locationSelected: null, idSelected: null, isLoading: false, idLoading: null, center: { lat: 0, lng: 0 } });