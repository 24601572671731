import { applySnapshot, getSnapshot, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  alarmsDefaultModel,
  mapDefaultModel,
  frameDefaultModel,
  userDefaultModel,

  alarm_neighborDefaultModel,
  Cart_CounterDefaultModel,
  cevtprDefaultModel,
  energy_efficiencyDefaultModel,
  energymonitorDefaultModel,
  freezerDefaultModel,
  gasDefaultModel,
  gatewayDefaultModel,
  level_channelDefaultModel,
  LevelContainerDefaultModel,
  lakeDefaultModel,
  levelsensor2DefaultModel,
  Level_TankDefaultModel,
  level_tunnelDefaultModel,
  loopsDefaultModel,
  mceDefaultModel,
  parkingDefaultModel,
  people_counterDefaultModel,
  residentialDefaultModel,
  roadsignDefaultModel,
  sense_4_20mADefaultModel,
  sense_modbusDefaultModel,
  TrackerDefaultModel,
  transformerDefaultModel,
  transitDefaultModel,
  weatherDefaultModel,
} from "models";

export const resetStore = (self: any) => {
  let initialState: any;
  return { afterCreate() { initialState = getSnapshot(self); }, resetStore() { applySnapshot(self, initialState); } };
};

export const RootStoreModel = types.model("RootStore").props({
  alarmsStore: alarmsDefaultModel(),
  frameStore: frameDefaultModel(),
  mapStore: mapDefaultModel(),
  usersStore: userDefaultModel(),

  alarm_neighborAlarmStore: alarm_neighborDefaultModel(),
  cart_counterAlarmStore: Cart_CounterDefaultModel(),
  CEVTPTAlarmStore: cevtprDefaultModel(),
  energy_efficiencyAlarmStore: energy_efficiencyDefaultModel(),
  energymonitorAlarmStore: energymonitorDefaultModel(),
  freezerAlarmStore: freezerDefaultModel(),
  gasAlarmStore: gasDefaultModel(),
  gatewayAlarmStore: gatewayDefaultModel(),
  level_channelAlarmStore: level_channelDefaultModel(),
  LevelContainerAlarmStore: LevelContainerDefaultModel(),
  lakeAlarmStore: lakeDefaultModel(),
  LevelSensor2AlarmStore: levelsensor2DefaultModel(),
  Level_TankAlarmStore: Level_TankDefaultModel(),
  level_tunnelAlarmStore: level_tunnelDefaultModel(),
  loopsAlarmStore: loopsDefaultModel(),
  mcesAlarmStore: mceDefaultModel(),
  parkingAlarmsStore: parkingDefaultModel(),
  people_counterAlarmStore: people_counterDefaultModel(),
  residentialAlarmStore: residentialDefaultModel(),
  roadsignAlarmStore: roadsignDefaultModel(),
  sense_4_20mAAlarmsStore: sense_4_20mADefaultModel(),
  sense_modbusAlarmStore: sense_modbusDefaultModel(),
  trackerAlarmStore: TrackerDefaultModel(),
  transformerAlarmsStore: transformerDefaultModel(),
  transitStore: transitDefaultModel(),
  weatherAlarmStore: weatherDefaultModel(),
});

export const rootStore = RootStoreModel.create();
export type RootStore = Instance<typeof RootStoreModel>;
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>;